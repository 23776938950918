import React, { useState, useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { updateTL } from '../../redux/slices/userSlice'; // Adjust with the actual path to your userSlice file
import { Badge, Box, Button } from '@mui/material';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationCard from './notification'; // Adjust with the actual path to your NotificationCard component
import TinkerLabListAPIs from '../../utilities/api/TinkersLab/TinkerLabListAPIs';
import CreateTL from './CreateTL'; // Adjust with the actual path to your CreateTL component
import { ImageBaseURL } from "../../utilities/api/axios";
import WebSocketService from '../../utilities/api/IMS/websocketService'; // Import your WebSocketService

const NavBar = () => {
    const [notifi, setNotifi] = useState(false);
    const [tinkerLabs, setTinkerLabs] = useState([]);
    const [openCreateTL, setOpenCreateTL] = useState(false);
    const [notifications, setNotifications] = useState([]); // State to store received notifications
    const { access, user } = useSelector((state) => state.user.user); // Access the user state
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socketRef = useRef(null); // Use a ref to keep track of the WebSocket instance

    const userAvatarUrl = user.profile_pic ? `${ImageBaseURL}${user.profile_pic}` : '';

    useEffect(() => {
        const fetchTinkerLabs = async () => {
            try {
                const labs = await TinkerLabListAPIs.TinkerLabsListGet();
                setTinkerLabs(labs);
            } catch (error) {
                console.error('Error fetching Tinker Labs data:', error);
            }
        };

        fetchTinkerLabs();
    }, [openCreateTL]);

    useEffect(() => {
        // Request permission for notifications when the component mounts
        if (Notification.permission !== 'granted') {
            Notification.requestPermission().then(permission => {
                if (permission !== 'granted') {
                    console.warn('Permission for notifications was denied.');
                }
            });
        }

        // WebSocket connection for receiving messages
        const connectWebSocket = () => {
            WebSocketService.connect(access, user.username); // Establish connection

            WebSocketService.onMessage((messageData) => {
                console.log("WebSocket message received:", messageData);
                // Ensure the message format is correct
                if (messageData && messageData.message && messageData.sender) {
                    // Update notifications with the new message
                    const newNotification = {
                        avatar: "https://www.example.com/avatar.jpg", // Replace with dynamic URL if available
                        message: `Message from ${messageData.sender}: ${messageData.message}`,
                        time: new Date().toLocaleTimeString() + " • Today",
                    };

                    setNotifications((prev) => [...prev, newNotification]);

                    // Show push notification
                    showNotification(newNotification);
                }
            });

            socketRef.current = WebSocketService; // Store the WebSocketService in the ref
        };

        connectWebSocket();

        // Cleanup: Close connection only when the user closes the chat window
        return () => {
            if (!notifi) {
                WebSocketService.disconnect();
            }
        };
    }, [access, user.username, notifi]); // Include notifi to control when the WebSocket disconnects

    // Function to display push notifications
    const showNotification = (notification) => {
        if (Notification.permission === 'granted') {
            new Notification('New Message', {
                body: notification.message,
                icon: notification.avatar,
            });
        }
    };

    const handleNavigation = () => {
        navigate('/calendar'); // Replace '/calendar' with your desired path
    };

    const handleProfile = () => {
        navigate('/profile'); // Replace '/profile' with your desired path
    };

    const handleTLChange = (event) => {
        const selectedTLName = event.target.value;
        dispatch(updateTL({ TL: selectedTLName }));
    };

    const handleCreateTLOpen = () => {
        setOpenCreateTL(true);
    };

    const handleCreateTLClose = () => {
        setOpenCreateTL(false);
    };

    // Determine whether to show the profile icon
    const showProfileIcon = 
        (user.is_org_admin && user.TL_name === "Org-Admin") || 
        !user.is_org_admin;

    const handleNotificationToggle = () => {
        setNotifi((prev) => !prev);
        // Do not disconnect the WebSocket here; keep it active
    };

    return (
        <>
            <AppBar position="static" color="inherit" elevation={1}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {user?.is_org_admin ? (
                            <Select
                                value={user.TL_name || ''}
                                onChange={handleTLChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select TL' }}
                            >
                                {tinkerLabs.map((lab) => (
                                    <MenuItem key={lab.id} value={lab.name}>
                                        {lab.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            user?.TL_name || ''
                        )}
                    </Typography>

                    {user?.TL_name === 'Org-Admin' && (
                        <Button variant="contained" color="primary" onClick={handleCreateTLOpen} sx={{ marginRight: '27px' }}>
                            Create TL
                        </Button>
                    )}

                    {/* Notification Icon with Badge */}
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '27px' }}>
                        <IconButton color="inherit" onClick={handleNotificationToggle}>
                            <Badge color="secondary" badgeContent={notifications.length} overlap="circular">
                                <NotificationAddIcon />
                            </Badge>
                        </IconButton>
                    </Box>

                    {user.TL_name !== "Org-Admin" && (
                      <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleNavigation}>
                          <CalendarTodayIcon />
                      </IconButton>
                    )}

                    {/* Conditional rendering of profile icon */}
                    {showProfileIcon && (
                        <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleProfile}>
                            <Avatar alt="User Avatar" src={userAvatarUrl} />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            {notifi && <NotificationCard notifications={notifications} />} {/* Pass notifications to NotificationCard */}
            <CreateTL open={openCreateTL} handleClose={handleCreateTLClose} />
        </>
    );
};

export default NavBar;


// import React, { useState, useEffect } from 'react';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import Avatar from '@mui/material/Avatar';
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import { useNavigate } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import { updateTL } from '../../redux/slices/userSlice'; // Adjust with the actual path to your userSlice file
// import { Badge, Box, Button } from '@mui/material';
// import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
// import NotificationCard from './notification'; // Adjust with the actual path to your NotificationCard component
// import TinkerLabListAPIs from '../../utilities/api/TinkersLab/TinkerLabListAPIs';
// import CreateTL from './CreateTL'; // Adjust with the actual path to your CreateTL component
// import { ImageBaseURL } from "../../utilities/api/axios";
// import WebSocketService from '../../utilities/api/IMS/websocketService'; // Import your WebSocketService

// const NavBar = () => {
//     const [notifi, setNotifi] = useState(false);
//     const [tinkerLabs, setTinkerLabs] = useState([]);
//     const [openCreateTL, setOpenCreateTL] = useState(false);
//     const [notifications, setNotifications] = useState([]); // Updated to store received notifications
//     const { access, user } = useSelector((state) => state.user.user); // Adjusted access to user state
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const userAvatarUrl = user.profile_pic ? `${ImageBaseURL}${user.profile_pic}` : '';

//     useEffect(() => {
//         const fetchTinkerLabs = async () => {
//             try {
//                 const labs = await TinkerLabListAPIs.TinkerLabsListGet();
//                 setTinkerLabs(labs);
//             } catch (error) {
//                 console.error('Error fetching Tinker Labs data:', error);
//             }
//         };

//         fetchTinkerLabs();
//     }, [openCreateTL]);

//     useEffect(() => {
//         // WebSocket connection for receiving messages
//         const connectWebSocket = () => {
//             WebSocketService.connect(access, user.username); // Use user.username for connection

//             WebSocketService.onMessage((messageData) => {
//                 console.log("WebSocket message received:", messageData);
//                 // Ensure the message format is correct
//                 if (messageData && messageData.message && messageData.sender) {
//                     // Update notifications with the new message
//                     setNotifications((prev) => [
//                         ...prev,
//                         {
//                             avatar: "https://www.example.com/avatar.jpg", // Replace with dynamic URL if available
//                             message: `Message from ${messageData.sender}: ${messageData.message}`,
//                             time: new Date().toLocaleTimeString() + " • Today",
//                         },
//                     ]);
//                 }
//             });
//         };

//         connectWebSocket();

//         return () => {
//             WebSocketService.disconnect(); // Clean up on component unmount
//         };
//     }, [access, user.username]); // Add access and username as dependencies

//     const handleNavigation = () => {
//         navigate('/calendar'); // Replace '/calendar' with your desired path
//     };

//     const handleProfile = () => {
//         navigate('/profile'); // Replace '/profile' with your desired path
//     };

//     const handleTLChange = (event) => {
//         const selectedTLName = event.target.value;
//         dispatch(updateTL({ TL: selectedTLName }));
//     };

//     const handleCreateTLOpen = () => {
//         setOpenCreateTL(true);
//     };

//     const handleCreateTLClose = () => {
//         setOpenCreateTL(false);
//     };

//     // Determine whether to show the profile icon
//     const showProfileIcon = 
//         (user.is_org_admin && user.TL_name === "Org-Admin") || 
//         !user.is_org_admin;

//     return (
//         <>
//             <AppBar position="static" color="inherit" elevation={1}>
//                 <Toolbar>
//                     <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//                         {user?.is_org_admin ? (
//                             <Select
//                                 value={user.TL_name || ''}
//                                 onChange={handleTLChange}
//                                 displayEmpty
//                                 inputProps={{ 'aria-label': 'Select TL' }}
//                             >
//                                 {tinkerLabs.map((lab) => (
//                                     <MenuItem key={lab.id} value={lab.name}>
//                                         {lab.name}
//                                     </MenuItem>
//                                 ))}
//                             </Select>
//                         ) : (
//                             user?.TL_name || ''
//                         )}
//                     </Typography>

//                     {user?.TL_name === 'Org-Admin' && (
//                         <Button variant="contained" color="primary" onClick={handleCreateTLOpen} sx={{ marginRight: '27px' }}>
//                             Create TL
//                         </Button>
//                     )}

//                     {/* Notification Icon with Badge */}
//                     <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '27px' }}>
//                         <IconButton color="inherit" onClick={() => setNotifi(!notifi)}>
//                             <Badge color="secondary" badgeContent={notifications.length} overlap="circular">
//                                 <NotificationAddIcon />
//                             </Badge>
//                         </IconButton>
//                     </Box>

//                     {user.TL_name !== "Org-Admin" && (
//                       <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleNavigation}>
//                           <CalendarTodayIcon />
//                       </IconButton>
//                     )}

//                     {/* Conditional rendering of profile icon */}
//                     {showProfileIcon && (
//                         <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleProfile}>
//                             <Avatar alt="User Avatar" src={userAvatarUrl} />
//                         </IconButton>
//                     )}
//                 </Toolbar>
//             </AppBar>
//             {notifi && <NotificationCard notifications={notifications} />} {/* Pass notifications to NotificationCard */}
//             <CreateTL open={openCreateTL} handleClose={handleCreateTLClose} />
//         </>
//     );
// };

// export default NavBar;
