import React, { useState } from 'react';
import { Box, Button, IconButton, Tabs, Tab, Typography, useMediaQuery, useTheme } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import AddInventoryDialog from './Popups/AddInventory';
import AddEquipmentDialog from './Popups/AddEquipment';
import AddMachineDialog from './Popups/AddMachine';
import ImportMachine from './Popups/ImportMachine';
import ImportEquipment from './Popups/ImportEquipment';
import ImportInventory from './Popups/ImportInventory';
import AddWorkbenchDialog from './Popups/AddWorkbench'; // Dummy Component

const TopBarComponent = ({ activeTab, setActiveTab, update, setUpdate, flag, setFlag, token }) => {
  const [isAddInventoryOpen, setAddInventoryOpen] = useState(false);
  const [isAddEquipmentOpen, setAddEquipmentOpen] = useState(false);
  const [isAddMachineOpen, setAddMachineOpen] = useState(false);
  const [isAddWorkbenchOpen, setAddWorkbenchOpen] = useState(false);
  const [isImportMachineOpen, setImportMachineOpen] = useState(false);
  const [isImportEquipmentOpen, setImportEquipmentOpen] = useState(false);
  const [isImportInventoryOpen, setImportInventoryOpen] = useState(false);

  const handleAddButtonClick = () => {
    switch (activeTab) {
      case 0:
        setAddMachineOpen(true);
        break;
      case 1:
        setAddEquipmentOpen(true);
        break;
      case 2:
        setAddInventoryOpen(true);
        break;
      case 3: // Handle Workbench case
        setAddWorkbenchOpen(true);  // Open the Add Workbench dialog
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    switch (activeTab) {
      case 0:
        setAddMachineOpen(false);
        break;
      case 1:
        setAddEquipmentOpen(false);
        break;
      case 2:
        setAddInventoryOpen(false);
        break;
      case 3: // For Workbench
        setAddWorkbenchOpen(false);  // Close the Add Workbench dialog
        break;
      default:
        break;
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleImportClick = () => {
    switch (activeTab) {
      case 0:
        setImportMachineOpen(true);
        break;
      case 1:
        setImportEquipmentOpen(true);
        break;
      case 2:
        setImportInventoryOpen(true);
        break;
      default:
        alert('This feature is not available at the moment');
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: isMobile ? 1 : 0,
      padding: theme.spacing(1),
      margin: 'none',
      marginBlock: '1rem',
      boxSizing: 'border-box',
    }}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        indicatorColor="none"
        sx={{
          color: 'black',
          padding: '5px',
          width: 'auto',
          marginBottom: isMobile ? 1 : 0,
          height: 'auto',
          background: '#F4F4F4',
          borderRadius: '12px',
          '& .Mui-selected': { 
            color: 'white',
            background: '#3F3F3F', 
            border: '1px solid #B5B5B5', 
            borderRadius: '8px' 
          },
          // '& .MuiTab-root': {
          //   color: '#000', // Set the default tab text color to black
          // }
        }}
      >

        <Tab label="Machines" sx={{ marginInline: 0.5, padding: '8px 32px', width: 'auto', height: '44px', typography: 'body1', fontWeight: '600' }} />
        <Tab label="Equipment" sx={{ marginInline: 0.5, padding: '8px 32px', width: 'auto', height: '44px', typography: 'body1', fontWeight: '600' }} />
        <Tab label="Inventory" sx={{ marginInline: 0.5, padding: '8px 32px', width: 'auto', height: '44px', typography: 'body1', fontWeight: '600' }} />
        <Tab label="Workbench" sx={{ marginInline: 0.5, padding: '8px 32px', width: 'auto', height: '44px', typography: 'body1', fontWeight: '600' }} /> {/* New Workbench Tab */}
      </Tabs>
      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', gap: 2 }}>
        {/* Selectively render import button based - not for Workbench */}
        {(activeTab === 0 || activeTab === 1 || activeTab === 2) && (
          <IconButton
            sx={{
              padding: '8px 16px',
              width: '136px',
              height: '44px',
              border: '1px solid #D3D3D3',
              borderRadius: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px'
            }}
            onClick={handleImportClick}
          >
            <DownloadIcon />
            <Typography variant="subtitle1" sx={{ fontWeight: 400, fontSize: '24px', color: '#5C5C5C' }}>Import</Typography>
          </IconButton>
        )}
        <Button
          variant="contained"
          onClick={handleAddButtonClick}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '12px 16px',
            width: '250px',
            height: '48px',
            background: '#3F3F3F',
            borderRadius: '12px',
            color: '#FFFFFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            '&:hover': { backgroundColor: '#333' }
          }}
        >
          <AddCircleOutlineIcon />
          {activeTab === 0 ? (
            <Typography variant="subtitle1">Add Machine</Typography>
          ) : activeTab === 1 ? (
            <Typography variant="subtitle1">Add Equipment</Typography>
          ) : activeTab === 2 ? (
            <Typography variant="subtitle1">Add Inventory</Typography>
          ) : (
            <Typography variant="subtitle1">Add Workbench</Typography>  // Add Workbench button label
          )}
        </Button>
      </Box>
      <AddInventoryDialog
        open={isAddInventoryOpen}
        update={update}
        setUpdate={setUpdate}
        flag={flag}
        setFlag={setFlag}
        onClose={() => { handleClose(activeTab) }}
      />
      <AddEquipmentDialog
        open={isAddEquipmentOpen}
        update={update}
        setUpdate={setUpdate}
        flag={flag}
        setFlag={setFlag}
        onClose={() => { handleClose(activeTab) }}
      />
      <AddMachineDialog
        open={isAddMachineOpen}
        update={update}
        setUpdate={setUpdate}
        flag={flag}
        setFlag={setFlag}
        onClose={() => { handleClose(activeTab) }}
      />
      <AddWorkbenchDialog
        open={isAddWorkbenchOpen}  // Ensure Workbench dialog opens
        update={update}
        setUpdate={setUpdate}
        flag={flag}
        setFlag={setFlag}
        onClose={() => { handleClose(activeTab) }}  // Ensure Workbench dialog closes
      />
      <ImportMachine
        token={token}
        isOpen={isImportMachineOpen}
        onClose={() => setImportMachineOpen(false)}
      />
      <ImportEquipment
        token={token}
        isOpen={isImportEquipmentOpen}
        onClose={() => setImportEquipmentOpen(false)}
      />
      <ImportInventory
        token={token}
        isOpen={isImportInventoryOpen}
        onClose={() => setImportInventoryOpen(false)}
      />
    </Box>
  );
};

export default TopBarComponent;
